
.short-video {
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .header-title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(37, 38, 50, 0.6);
      text-align: left;
      font-style: normal;

      .title-main {
        font-size: 18px;
        color: #111;
        margin-right: 10px;
        font-weight: 600;
      }
    }

    .header-operation {
      display: flex;
      align-items: center;
      .radio-group {
        padding: 4px;
        border-radius: 4px;
        background-color: #f2f2f4;
        margin-right: 10px;

        ::v-deep .el-radio-button__orig-radio + .el-radio-button__inner {
          background-color: transparent;
          color: rgba(37, 38, 50, 0.6);
          font-size: 14px;
          font-weight: 400;
          border-color: transparent;
          box-shadow: none;
          border-radius: 4px;
          padding: 2px 24px;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          background-color: #ffffff;
          color: #594fee;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
          color: #594fee;
        }

        ::v-deep .el-radio-button__inner:hover {
          color: #594fee;
        }
      }

      ::v-deep .el-button {
        margin-left: 10px;
        padding: 6px 12px;
        background-color: #f2f2f4;
        border: none;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777880;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      ::v-deep .el-date-editor .el-range-input {
        background-color: transparent;
      }

      ::v-deep .el-date-editor--daterange.el-input,
      .el-date-editor--daterange.el-input__inner,
      .el-date-editor--timerange.el-input,
      .el-date-editor--timerange.el-input__inner {
        width: 250px;
      }

      ::v-deep .el-range-editor.el-input__inner {
        background-color: #f2f2f4;
        border: none;
      }
    }
  }

  .content-box {
    height: 405px;
    border: 1px solid #f2f2f4;
    box-sizing: border-box;

    .content-buttons {
      display: flex;
      align-items: center;

      .button-item {
        width: calc(100% / 8);
        height: 90px;
        background-color: #f9f9fa;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f4;
        padding: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #252632;
        text-align: left;
        font-style: normal;
        cursor: pointer;

        &.is-active {
          .item-label {
            color: #594fee;
          }
          background-color: #fff;
          border-top: 2px solid #594fee;
          border-bottom: none;
        }

        .item-data {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 700;
        }

        &:not(.button-item:last-of-type) {
          border-right: 1px solid #f2f2f4;
        }
      }
    }

    .content-data {
      height: calc(100% - 90px);
      box-sizing: border-box;
      // background-color: #111;
      padding: 16px;
      display: flex;
      flex-direction: column;

      .data-header {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 600;
        font-family: PingFang SC, PingFang SC;
        color: #252632;
        flex-grow: 0;
      }

      .data {
        flex: 1;

        .video-no-data {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 32px;

          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }

          span {
            font-size: 16px;
            font-weight: 600;
            color: #25263299;
            font-family: PingFang SC, PingFang SC;
          }
        }

        .video-chart {
          height: 100%;
          width: 100%;

          .chart {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
