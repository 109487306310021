
::v-deep .el-button {
  border: none;
  background-color: #594fee;
  color: #fff;
}
.business-analysis {
  .box-header {
  }

  .content-box {
    background-color: #fff;
    min-height: calc(100vh - 235px);
    margin-top: 20px;
    padding: 0 24px 24px 24px;
    border-radius: 8px;
    box-sizing: border-box;

    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      border-color: #594fee;
    }
  }
}
